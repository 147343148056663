<template>
    <div>
        <el-table
            :data="tableList"
            height="500"
            class="center-order-table">
            <!-- 订单详情 -->
            <el-table-column
                :label="tableTitle"
                align="center"
                width="350">
                <template v-slot="{row}">
                    <center-order-table-detail
                        :order-detail="row">
                    </center-order-table-detail>
                </template>
            </el-table-column>
            <!-- 价格 -->
            <el-table-column
                label="价格"
                align="center">
                <template v-slot="{row}">
                    <!-- 价格 -->
                    <slot name="price"
                        :price="row.price"
                        :markup="100"
                        :type="'(套餐一)'">
                    </slot>
                </template>
            </el-table-column>
            <!-- 实付款 -->
            <el-table-column
                label="实付款"
                prop=""
                align="center"
                show-overflow-tooltip>
                <template v-slot="{row}">
                    <div class="center-order-table-payment">
                        <!-- 实付款 -->
                        <slot name="payment"
                            :price="row.rprice"
                            :discount="100"
                            :type="'(校园优惠)'">
                        </slot>
                    </div>
                </template>
            </el-table-column>
            <!-- 交易状态 -->
            <el-table-column
                label="交易状态"
                align="center"
                show-overflow-tooltip>
                <template v-slot="{row}">
                    <div class="center-order-table-status">
                        <!-- 交易状态 -->
                        <slot name="status"
                            :status="row.orderStatus"
                            :id="row.orderID">
                        </slot>
                    </div>
                </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column
                label="操作"
                prop=""
                align="center"
                show-overflow-tooltip>
                <template v-slot="{row}">
                    <div class="center-order-table-operate">
                        <!-- 操作 -->
                        <slot name="handle"
                            v-bing:status="row.orderStatus"
                            v-bing:id="row.orderID"
                            :row="row">
                        </slot>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    props: {
        tableTitle: String,
        tableList: {
            type: Array,
            default() {return []}
        }
    },
    components: {
        centerOrderTableDetail: () => import("@/components/centerOrderTableDetail")
    }
}
</script>

<style scoped>
/* 交易状态 & 操作 */
.center-order-table-status,
.center-order-table-operate{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100px;
}
</style>