var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{staticClass:"center-order-table",attrs:{"data":_vm.tableList,"height":"500"}},[_c('el-table-column',{attrs:{"label":_vm.tableTitle,"align":"center","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('center-order-table-detail',{attrs:{"order-detail":row}})]}}])}),_c('el-table-column',{attrs:{"label":"价格","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._t("price",null,{"price":row.price,"markup":100,"type":'(套餐一)'})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"实付款","prop":"","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"center-order-table-payment"},[_vm._t("payment",null,{"price":row.rprice,"discount":100,"type":'(校园优惠)'})],2)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"交易状态","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"center-order-table-status"},[_vm._t("status",null,{"status":row.orderStatus,"id":row.orderID})],2)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作","prop":"","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"center-order-table-operate"},[_vm._t("handle",null,{"row":row})],2)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }